import React, { useEffect, useState, useRef } from "react";
import { ActivityIndicator, Linking, Platform, View } from "react-native";
import { Card, Divider, Text, withTheme, Button } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

import { desktopBreakpoint } from "../../shared/helpers";
import { removeAllOrderItems } from "../../store/actions/cartActions";
import { getOrderStatusByUid } from "../../store/actions/orderActions";
import { setOrderStatus } from "../../store/actions/orderStatusActions";
import { store } from "../../store/store";
import ArticleList from "./ArticleList";
import CustomerInformations from "./CustomerInformations";
import OrderInformations from "./OrderInformations";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { getPaymentLink } from "../../store/actions/paymentActions";
import { styles } from "../../shared/styles";
import CustomIconButton from "../../helpers/IconButton/CustomIconButton";

const OrderTracking = ({ orderStatus, theme, match, windowWidth, settings }) => {
    const history = useHistory();
    const location = useLocation();
    const fromOrderHistory = useRef(location.state?.fromOrderHistory);
    const [loading, setLoading] = useState(true);
    const [initialFetchComplete, setInitialFetchComplete] = useState(false);
    const idRef = useRef(match.params.id);
    const isFetching = useRef(false);
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const [paymentStatusFromUrl, setPaymentStatusFromUrl] = useState(null);

    const mobile = windowWidth < desktopBreakpoint;

    const id = orderStatus?.data ? orderStatus.data.uid : null;
    const orderState = orderStatus?.data?.orderState;
    const isGuaranteedInvoicePayment = orderStatus?.data?.paymentTypeString === "Rechnung";
    const isPrepayment = orderStatus?.data?.paymentTypeString === "Vorkasse";
    const isGuaranteedSepaPayment = orderStatus?.data?.paymentTypeString === "Lastschrift";
    const isPaymentSuccessful = orderState === 5;
    const isCanceled = orderState === 7;
    const isStorno = orderState === 9;
    const isAbleToChangePaymentType = orderState < 5 && (!paymentStatusFromUrl || isPrepayment);

    useEffect(() => {
        const fetchOrderStatus = async () => {
            const result = await store.dispatch(getOrderStatusByUid(idRef.current));
            store.dispatch(removeAllOrderItems());
            store.dispatch(setOrderStatus(result));
            setSelectedPaymentType(result?.PaymentTypes?.name || "");
            setInitialFetchComplete(true);
            setLoading(false);
        };

        fetchOrderStatus();

        if (match.params.paymentSuccessful) {
            setPaymentStatusFromUrl(match.params.paymentSuccessful === "true");
        }
    }, []);

/*     useEffect(() => {
        let intervalId;

        if (initialFetchComplete && orderStatus.data && !isPrepayment) {
            intervalId = setInterval(async () => {
                if (!isFetching.current && orderState < 5) {
                    isFetching.current = true;
                    const result = await store.dispatch(getOrderStatusByUid(idRef.current));
                    store.dispatch(setOrderStatus(result));
                    isFetching.current = false;

                    if (result.orderState >= 5) {
                        clearInterval(intervalId);
                    }
                }
            }, 10000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [initialFetchComplete, orderStatus]); */

    const handleGetPaymentLink = async () => {
        const selectedPaymentTypeObj = settings?.store?.availablePaymentTypes.find(
            (type) => type.name === selectedPaymentType
        );
        if (!selectedPaymentTypeObj) return;

        const result = await store.dispatch(getPaymentLink(id, selectedPaymentTypeObj.paymentTypesId));

        const paymentLink = result?.paymentLink;
        if (!paymentLink) return;

        Platform.OS === "web" ? (window.location.href = paymentLink) : Linking.openURL(paymentLink);
    };

    const getHeadline = () => {
        if (isCanceled || isStorno) {
            return isCanceled
                ? "Ihre Bestellung wurde storniert."
                : "Ihre Bestellung wurde storniert.\nWir werden schnellstmöglich eine Rückerstattung durchführen.";
        } else if ((paymentStatusFromUrl && !isPrepayment) || isPaymentSuccessful) {
            return "Vielen Dank für Ihre Bestellung -\nwir kümmern uns schnellstmöglich um den Versand.";
        } else if (isPrepayment) {
            return "Vielen Dank für Ihre Bestellung -\nSobald Ihre Zahlung eingegangen ist, kümmern wir uns schnellstmöglich um den Versand.";
        } else {
            return "Ihre Bestellung wurde erfasst, aber die Zahlung ist noch nicht abgeschlossen.";
        }
    };

    return (
        <View
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <View style={{ width: mobile ? 350 : 800 }}>
                {/* Loading indicator */}
                {loading && (
                    <ActivityIndicator
                        size={"large"}
                        style={{ marginTop: 100 }}
                        animated
                        color={theme.colors.primary}
                    />
                )}
                {/* Back button to order history */}
                {!loading && fromOrderHistory.current && (
                    <View style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
                        <CustomIconButton
                            icon="arrow-left"
                            tooltip="Zurück"
                            position="right"
                            onPress={() => history.push("/customer/orderHistory/")}
                        ></CustomIconButton>
                        <Text style={{ marginTop: 17 }}>Zurück zur Bestellhistorie</Text>
                    </View>
                )}
                {/* No order found */}
                {!loading && (!id || !orderStatus.data) && (
                    <Text variant="headlineSmall" style={{ marginTop: 50, textAlign: "center" }}>
                        Keine passende Bestellung gefunden
                    </Text>
                )}
                {!loading && id && orderStatus.data && (
                    <View>
                        <Text variant="headlineSmall" style={{ marginTop: 50, textAlign: "center" }}>
                            {getHeadline()}
                        </Text>
                        {/* Payment type picker */}
                        {isAbleToChangePaymentType && (
                            <View style={{ marginTop: 20, alignItems: "center" }}>
                                <Text variant="bodyLarge" style={{ marginBottom: 10, textAlign: "center" }}>
                                    {isPrepayment
                                        ? "Sie haben auch die Möglichkeit, mit einer anderen Zahlungsart zu zahlen:"
                                        : "Bitte schließen Sie die Zahlung mit Ihrer gewünschten Zahlungsart ab:"}
                                </Text>
                                <Picker
                                    selectedValue={selectedPaymentType}
                                    style={styles.picker}
                                    onValueChange={(value) => setSelectedPaymentType(value)}
                                >
                                    {settings?.store?.availablePaymentTypes.map(({ name }) => (
                                        <Picker.Item key={name} label={name} value={name} />
                                    ))}
                                </Picker>
                                <Button mode="contained" onPress={handleGetPaymentLink} style={{ marginTop: "15px" }}>
                                    Zur Zahlungsseite
                                </Button>
                            </View>
                        )}
                        <OrderOverviewCard orderStatus={orderStatus} mobile={mobile} />
                    </View>
                )}
            </View>
        </View>
    );
};

function OrderOverviewCard({ orderStatus, mobile }) {
    const { data } = orderStatus;

    return (
        <Card
            style={{
                marginVertical: mobile ? 20 : 40,
                marginBottom: 70,
                borderRadius: 13,
            }}
        >
            <Card.Title
                style={{ marginTop: 16 }}
                title={<Text variant="headlineSmall">Ihre Bestellung (Nr. {data.orderNumber})</Text>}
            />
            <Card.Content style={{ marginBottom: 16 }}>
                <View>
                    <OrderInformations data={data} />
                    <Divider style={{ marginVertical: 20 }} />
                    <CustomerInformations TransactionBuyers={data.TransactionBuyers} />
                    <Divider style={{ marginVertical: 20 }} />
                    <ArticleList Order={data} />
                </View>
            </Card.Content>
        </Card>
    );
}

function mapStateToProps(state) {
    const { orderStatus, settings } = state;
    return { orderStatus, settings };
}

export default connect(mapStateToProps)(withRouter(withTheme(OrderTracking)));
