import { SET_GAST } from "../actions/gastAction";

const initialState = {};

export default function gastReducer(state = initialState, action) {
    switch (action.type) {
        case SET_GAST:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
