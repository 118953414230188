import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { DraxList, DraxProvider } from "react-native-drax";
import { ActivityIndicator, Button, Dialog, FAB, IconButton, Portal, Text, withTheme } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Config from "../../../Config";
import { withHooksHOC, getContrastColor, getAccessToken } from "../../shared/helpers";
import { createCustomerCourses, deleteCustomerCourse, updateCustomerCourses } from "../../store/actions/accountActions";
import {
    addRecipesToWeeklyPlan,
    getRecipesForOnlineShopForWeeklyPlan,
    setOnlineShopItemType,
    updateCookingProcess,
} from "../../store/actions/onlineShopCategoriesActions";
import { deleteCustomerCookingProcess, getAllCourses, getWeeklyPlan } from "../../store/actions/weeklyPlanActions";
import { store } from "../../store/store";
import AddToWeeklyPlanDialog from "../shop/addToWeeklyPlanDialog/AddToWeeklyPlanDialog";
import ChooseRecipeDialog from "../shop/chooseRecipeDialog/ChooseRecipeDialog";
import DaysNavigation from "./mobile/DaysNavigation";
import Header from "./mobile/Header";
import WeeklyPlanCategory from "./WeeklyPlanCategory";
import WeeklyPlanDays from "./WeeklyPlanDays";
import { WeeklyPlanStyles } from "./WeeklyPlanStyles";
import { styles } from "../../shared/styles";
import { showToast } from "../../helpers/toastMessage/ToastMessage";
import ConfirmDeletePopup from "../customerScreens/ConfirmDeletePopup";

const config = new Config();
const accessToken = getAccessToken();

class WeeklyPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMonday: this.getMonday(new moment()),
            cookingProcesses: [],
            weeklyPlan: [],
            width: 0,
            courses: [],
            fabOpen: false,
            showAddCoursePopupVisible: false,
            selectedCustomerCourse: undefined,
            allCourses: [],
            recipes: [],
            selectedDay: 1,
            showConfirmDeletePopup: false,
            customerCoursesIdToDelete: undefined,
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onPrevWeekClick = this.onPrevWeekClick.bind(this);
        this.onNextWeekClick = this.onNextWeekClick.bind(this);
        this.loadWeeklyPlan = this.loadWeeklyPlan.bind(this);
        this.groupBy = this.groupBy.bind(this);
        this.onFabStateChange = this.onFabStateChange.bind(this);
        this.reOrderCourse = this.reOrderCourse.bind(this);
        this.deleteCookingProcess = this.deleteCookingProcess.bind(this);
        this.deleteCustomerCourse = this.deleteCustomerCourse.bind(this);
        this.toggleAddCoursePopup = this.toggleAddCoursePopup.bind(this);
        this.addCustomerCourse = this.addCustomerCourse.bind(this);
        this.editCookingProcess = this.editCookingProcess.bind(this);
        this.toggleHoverOut = this.toggleHoverOut.bind(this);
        this.toggleHoverIn = this.toggleHoverIn.bind(this);
        this.showAddRecipeToWeeklyPlanPopup = this.showAddRecipeToWeeklyPlanPopup.bind(this);
        this.updateCookingProcess = this.updateCookingProcess.bind(this);
        this.toggleAddToWeeklyPlanDialog = this.toggleAddToWeeklyPlanDialog.bind(this);
        this.toggleChooseRecipeDialog = this.toggleChooseRecipeDialog.bind(this);
        this.addRecipeToWeeklyPlan = this.addRecipeToWeeklyPlan.bind(this);
        this.selectWeeklyPlanEntry = this.selectWeeklyPlanEntry.bind(this);
        this.handleReorderCources = this.handleReorderCources.bind(this);
        this.toggleConfirmDeletePopup = this.toggleConfirmDeletePopup.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();

        window.addEventListener("resize", this.updateWindowDimensions);

        store.dispatch(setOnlineShopItemType(-2));

        this.setState({
            loading: true,
        });

        store.dispatch(getRecipesForOnlineShopForWeeklyPlan()).then((result) => {
            this.setState({
                recipes: result,
            });
        });

        this.loadWeeklyPlan();

        store.dispatch(getAllCourses()).then((courses) => {
            if (courses) {
                const defaultCourse = courses.find((course) => course.isDefault === true);
                this.setState({
                    allCourses: courses,
                    selectedCourseId: defaultCourse ? defaultCourse.courseId : courses[0].courseId,
                });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.account.customer.customerCourses !== this.props.account.customer.customerCourses) {
            this.updateAvailableCourses();
        }
    }

    getAvailableCourses = (allCourses, customerCourses) => {
        return allCourses.filter(
            (course) => !customerCourses.find((customerCourse) => customerCourse.coursesId === course.courseId)
        );
    };

    updateAvailableCourses = () => {
        const { allCourses } = this.state;
        const availableCourses = this.getAvailableCourses(allCourses, this.props.account.customer.customerCourses);
        this.setState({
            selectedCourseId: availableCourses.length > 0 ? availableCourses[0].courseId : undefined,
        });
    };

    getCourseById(courseId) {
        const numericCourseId = parseInt(courseId, 10);
        return this.state.allCourses.find((course) => course.courseId === numericCourseId);
    }

    addRecipeToWeeklyPlan(customerCoursesId, articleSizesId, recipesId, dayToCook, amount) {
        this.setState({
            loading: true,
        });
        var recipesToWeeklyPlanDto = {
            customersUid: this.props.account.customer.customerUid,
            RecipesForWeeklyPlan: [
                {
                    recipesId: recipesId,
                    articleSizesId: articleSizesId,
                    amount: amount,
                    dayToCook: dayToCook,
                    customerCourse: customerCoursesId,
                },
            ],
        };
        store.dispatch(addRecipesToWeeklyPlan(recipesToWeeklyPlanDto, accessToken)).then((result) => {
            this.loadWeeklyPlan();
        });
    }

    deleteCustomerCourse(customerCoursesId) {
        store.dispatch(deleteCustomerCourse(customerCoursesId, accessToken));
    }

    toggleConfirmDeletePopup = (customerCoursesId) => {
        if (this.props.account.customer.customerCourses.length > 1) {
            this.setState((prevState) => ({
                showConfirmDeletePopup: !prevState.showConfirmDeletePopup,
                customerCoursesIdToDelete: customerCoursesId,
            }));
        } else {
            showToast("Es muss mindestens ein Gang vorhanden sein", "information-circle-outline", "error");
        }
    };

    toggleAddCoursePopup() {
        this.setState({
            showAddCoursePopupVisible: !this.state.showAddCoursePopupVisible,
        });
    }

    loadWeeklyPlan() {
        try {
            let biosInstances = [];
            biosInstances.push({ url: config.backendHost });
            this.setState({
                cookingProcesses: [],
                weeklyPlan: [],
            });
            for (let instance of biosInstances) {
                store.dispatch(getWeeklyPlan(this.state.currentMonday, accessToken)).then((result) => {
                    var tempCookingProcesses = this.state.cookingProcesses.concat(result);
                    var groupedCookingProcesses = this.groupBy(tempCookingProcesses, function (item) {
                        return [item.CustomerCourse.customerCoursesId];
                    });
                    this.setState({
                        cookingProcesses: this.state.cookingProcesses.concat(result),
                        weeklyPlan: groupedCookingProcesses,
                        addToWeeklyPlanVisible: false,
                        chooseRecipeDialogVisible: false,
                        selectedCustomerCoursesId: undefined,
                        selectedCookingProcess: undefined,
                        loading: false,
                    });
                });
            }
        } catch {}
    }

    groupBy(array, f) {
        var groups = {};
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: window.innerWidth <= 768,
        });
    }

    getMonday(d) {
        return new moment(d).startOf("week").add(5, "hours");
    }

    onPrevWeekClick() {
        var current = new moment(this.state.currentMonday);
        current.add(-1, "week");
        this.setState(
            {
                currentMonday: this.getMonday(current),
            },
            this.loadWeeklyPlan()
        );
    }

    onNextWeekClick() {
        var current = new moment(this.state.currentMonday);
        current.add(1, "week");
        this.setState(
            {
                currentMonday: this.getMonday(current),
            },
            this.loadWeeklyPlan()
        );
    }

    deleteCookingProcess(cookingProcess) {
        store.dispatch(deleteCustomerCookingProcess(cookingProcess.cookingProcessId, accessToken)).then((result) => {
            this.loadWeeklyPlan();
        });
    }

    editCookingProcess(cookingProcess) {
        this.showAddRecipeToWeeklyPlanPopup(this.props.account.customer.customerUid, cookingProcess);
    }

    addCustomerCourse() {
        this.setState({
            loading: true,
        });
        const selectedCourse = this.getCourseById(this.state.selectedCourseId);
        if (selectedCourse) {
            store.dispatch(createCustomerCourses(selectedCourse.courseId, accessToken)).then((result) => {
                this.toggleAddCoursePopup();
                this.setState({
                    loading: false,
                });
            });
        } else {
            console.error("Kurs nicht gefunden");
            this.setState({
                loading: false,
            });
        }
    }

    selectWeeklyPlanEntry(customerCoursesId, date) {
        this.setState({
            selectedCustomerCoursesId: customerCoursesId,
            selectedDate: date,
            chooseRecipeDialogVisible: true,
        });
    }

    toggleHoverOut() {
        this.setState({
            toggleDayIndex: undefined,
            toggleCourseIndex: undefined,
        });
    }

    toggleHoverIn(dayIndex, courseIndex) {
        this.setState({
            toggleDayIndex: dayIndex,
            toggleCourseIndex: courseIndex,
        });
    }

    onFabStateChange() {
        this.setState({
            fabOpen: !this.state.fabOpen,
        });
    }

    reOrderCourse(course, value) {
        let courses = this.props.account.customer.customerCourses;
        if (
            value === 1 &&
            course.orderIndex ===
                Math.max.apply(
                    Math,
                    courses.map(function (o) {
                        return o.orderIndex;
                    })
                )
        ) {
            return;
        }
        if (
            value === -1 &&
            course.orderIndex ===
                Math.min.apply(
                    Math,
                    courses.map(function (o) {
                        return o.orderIndex;
                    })
                )
        ) {
            return;
        }
        let courseOrderIndex = course.orderIndex;
        courses.forEach(function (c, index) {
            if (c.orderIndex === courseOrderIndex + value && c.customerCoursesId !== course.customerCoursesId) {
                c.orderIndex = courseOrderIndex;
            }
            if (c.customerCoursesId === course.customerCoursesId) {
                c.orderIndex = courseOrderIndex + value;
            }
        });

        store.dispatch(
            updateCustomerCourses(
                accessToken,
                courses.length > 0 &&
                    courses.map((course, index) => {
                        return { customerCoursesId: course.customerCoursesId, orderIndex: course.orderIndex };
                    })
            )
        );
    }

    showAddRecipeToWeeklyPlanPopup(customerUid, selectedCookingProcess) {
        this.setState({
            addToWeeklyPlanVisible: true,
            customerUid: customerUid,
            portalVisible: true,
            selectedCookingProcess: selectedCookingProcess,
        });
    }

    updateCookingProcess(customerCoursesId, articleSizesId, dayToCook, amount, cookingProcessId) {
        this.setState({
            loading: true,
        });
        var updateCookingProcessDto = {
            customersUid: this.state.customerUid,
            articleSizesId: articleSizesId,
            amount: amount,
            dayToCook: dayToCook,
            customerCourse: customerCoursesId,
            cookingProcessId: cookingProcessId,
        };
        store.dispatch(updateCookingProcess(updateCookingProcessDto, accessToken)).then((result) => {
            this.loadWeeklyPlan();
        });
    }

    toggleAddToWeeklyPlanDialog() {
        var visibility = !this.state.addToWeeklyPlanVisible;
        this.setState({
            addToWeeklyPlanVisible: visibility,
        });
    }

    toggleChooseRecipeDialog() {
        var visibility = !this.state.chooseRecipeDialogVisible;
        this.setState({
            chooseRecipeDialogVisible: visibility,
        });
    }

    handleReorderCources(courses) {
        store.dispatch(
            updateCustomerCourses(
                accessToken,
                courses.length > 0 &&
                    courses.map((course, index) => {
                        return { customerCoursesId: course.customerCoursesId, orderIndex: index };
                    })
            )
        );
    }

    render() {
        const { isMobile } = this.state;
        var counter = 0;
        const availableCourses = this.getAvailableCourses(
            this.state.allCourses,
            this.props.account.customer.customerCourses
        );

        return (
            <View nativeID="WeeklyPlan" style={WeeklyPlanStyles.container}>
                <View style={WeeklyPlanStyles.desktop}>
                    {!isMobile ? (
                        <View
                            style={{
                                ...StyleSheet.flatten(WeeklyPlanStyles.weekNavigation),
                                backgroundColor: this.props.theme.colors.background,
                            }}
                        >
                            <IconButton
                                icon={"chevron-left"}
                                size={30}
                                onPress={this.onPrevWeekClick}
                                iconColor={this.props.theme.colors.primary}
                                style={{
                                    width: "40px",
                                    height: "75px",
                                    margin: 0,
                                }}
                            />
                            <WeeklyPlanDays props={this.props} monday={this.state.currentMonday} />
                            <IconButton
                                icon={"chevron-right"}
                                size={30}
                                onPress={this.onNextWeekClick}
                                iconColor={this.props.theme.colors.primary}
                                style={{
                                    width: "40px",
                                    height: "75px",
                                    margin: 0,
                                }}
                            />
                        </View>
                    ) : (
                        <View>
                            <Header
                                monday={this.state.currentMonday}
                                onNextWeek={this.onNextWeekClick}
                                onPrevWeek={this.onPrevWeekClick}
                            />
                            <DaysNavigation
                                monday={this.state.currentMonday}
                                onSelect={(day) => this.setState({ selectedDay: day })}
                            />
                        </View>
                    )}
                    <View>
                        {this.props.account.customer.customerCourses &&
                            this.props.account.customer.customerCourses.length > 0 && (
                                <DraxProvider>
                                    <View>
                                        <DraxList
                                            data={this.props.account.customer.customerCourses}
                                            lockItemDragsToMainAxis={true}
                                            renderItemContent={({ item, index }) => {
                                                var courseEntries = this.state.weeklyPlan.find(
                                                    (c) =>
                                                        c[0].CustomerCourse.customerCoursesId === item.customerCoursesId
                                                );
                                                counter++;
                                                return (
                                                    <View key={index}>
                                                        <WeeklyPlanCategory
                                                            courseEntries={courseEntries}
                                                            key={item.customerCoursesId}
                                                            counter={counter}
                                                            course={item}
                                                            props={this.props}
                                                            reOrderCourse={this.reOrderCourse}
                                                            toggleConfirmDeletePopup={this.toggleConfirmDeletePopup}
                                                            toggleHoverIn={this.toggleHoverIn}
                                                            toggleHoverOut={this.toggleHoverOut}
                                                            deleteCookingProcess={this.deleteCookingProcess}
                                                            editCookingProcess={this.editCookingProcess}
                                                            selectWeeklyPlanEntry={this.selectWeeklyPlanEntry}
                                                            currentMonday={this.state.currentMonday}
                                                            toggleCourseIndex={this.state.toggleCourseIndex}
                                                            toggleDayIndex={this.state.toggleDayIndex}
                                                            selectedDay={this.state.selectedDay}
                                                        />
                                                    </View>
                                                );
                                            }}
                                            onItemReorder={({ fromIndex, toIndex }) => {
                                                const newData = this.props.account.customer.customerCourses.slice();
                                                newData.splice(toIndex, 0, newData.splice(fromIndex, 1)[0]);
                                                this.handleReorderCources(newData);
                                            }}
                                            keyExtractor={(item) => item.customerCoursesId}
                                        />
                                    </View>
                                </DraxProvider>
                            )}
                    </View>
                    <Portal>
                        {this.props.account.customer.customerCourses.length < 3 && (
                            <FAB
                                style={{
                                    ...StyleSheet.flatten(WeeklyPlanStyles.fab),
                                    backgroundColor: this.props.theme.colors.accent,
                                }}
                                color={getContrastColor(this.props.theme.colors.accent)}
                                icon="plus"
                                onPress={() => this.toggleAddCoursePopup()}
                            />
                        )}
                        <Dialog
                            visible={this.state.showAddCoursePopupVisible}
                            onDismiss={this.toggleAddCoursePopup}
                            style={{
                                backgroundColor: this.props.theme.colors.surface,
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                            dismissable={false}
                        >
                            <IconButton
                                icon="close"
                                size={24}
                                onPress={this.toggleAddCoursePopup}
                                style={[styles.dialogCloseButton, { backgroundColor: this.props.theme.colors.primary }]}
                                iconColor={getContrastColor(this.props.theme.colors.primary)}
                                rippleColor="transparent"
                            />
                            <Dialog.Title style={[styles.dialogHeadline, { paddingRight: 40 }]}>
                                Gang hinzufügen
                            </Dialog.Title>
                            <Dialog.Content>
                                {this.state.loading && (
                                    <View
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ActivityIndicator
                                            size={"large"}
                                            animating={true}
                                            color={this.props.theme.colors.primary}
                                        />
                                    </View>
                                )}
                                <Text style={{ marginBottom: 10 }}>Gang</Text>
                                <Picker
                                    selectedValue={this.state.selectedCourseId}
                                    onValueChange={(itemValue) => this.setState({ selectedCourseId: itemValue })}
                                    style={[
                                        WeeklyPlanStyles.weeklyPlanSelect,
                                        {
                                            backgroundColor: this.props.theme.colors.surface,
                                            borderColor: this.props.theme.colors.primary,
                                            color: getContrastColor(this.props.theme.colors.surface),
                                        },
                                    ]}
                                >
                                    {availableCourses.map((course) => (
                                        <Picker.Item
                                            key={course.courseId}
                                            label={course.name}
                                            value={course.courseId}
                                        />
                                    ))}
                                </Picker>
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button
                                    textColor={getContrastColor(this.props.theme.colors.primary)}
                                    style={{
                                        backgroundColor: this.props.theme.colors.error,
                                        marginRight: 20,
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                    }}
                                    uppercase={false}
                                    onPress={this.toggleAddCoursePopup}
                                >
                                    Abbrechen
                                </Button>
                                <Button
                                    textColor={getContrastColor(this.props.theme.colors.primary)}
                                    style={{
                                        backgroundColor: this.props.theme.colors.primary,
                                        marginRight: 0,
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                    }}
                                    uppercase={false}
                                    onPress={this.addCustomerCourse}
                                >
                                    Hinzufügen
                                </Button>
                            </Dialog.Actions>
                        </Dialog>
                        {this.state.selectedCookingProcess && (
                            <AddToWeeklyPlanDialog
                                addToWeeklyPlanVisible={this.state.addToWeeklyPlanVisible}
                                toggleAddToWeeklyPlanDialog={this.toggleAddToWeeklyPlanDialog}
                                loading={this.state.loading}
                                selectedCookingProcess={this.state.selectedCookingProcess}
                                customerCourses={this.state.customerCourses}
                                articleSizes={
                                    this.state.selectedRecipe ? this.state.selectedRecipe.articleSizes : undefined
                                }
                                recipesId={0}
                                updateCookingProcess={this.updateCookingProcess}
                            />
                        )}
                        {this.state.selectedCustomerCoursesId && (
                            <ChooseRecipeDialog
                                chooseRecipeDialogVisible={this.state.chooseRecipeDialogVisible}
                                toggleChooseRecipeDialog={this.toggleChooseRecipeDialog}
                                loading={this.state.loading}
                                selectedCustomerCoursesId={this.state.selectedCustomerCoursesId}
                                recipes={this.state.recipes}
                                selectedDate={this.state.selectedDate}
                                updateCookingProcess={this.updateCookingProcess}
                                addRecipeToWeeklyPlan={this.addRecipeToWeeklyPlan}
                            />
                        )}
                        {this.state.showConfirmDeletePopup && (
                            <ConfirmDeletePopup
                                visible={this.state.showConfirmDeletePopup}
                                togglePopup={this.toggleConfirmDeletePopup}
                                theme={this.props.theme}
                                title="Gang löschen?"
                                content="Möchten Sie diesen Gang wirklich unwiderruflich löschen?"
                                // onConfirm={() => this.deleteCustomerCourse()}
                                onConfirm={() => this.deleteCustomerCourse(this.state.customerCoursesIdToDelete)}
                            />
                        )}
                    </Portal>
                </View>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}
export default connect(mapStateToProps)(withRouter(withTheme(withHooksHOC(WeeklyPlan))));
