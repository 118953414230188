import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";
import { fetchWithSessionHandling } from "../../helpers/sessionHandling/fetchWithSessionHandling";

const config = new Config();

export function postOrder(order, accessToken = null) {
    if (!order || typeof order !== "object") {
        return;
    }

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
        body: JSON.stringify(order),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/cashregister/saveOrder`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };

    // re-enable this once the azure function is ready
    // const requestOptions = {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${accessToken}`,
    //         shopIdentification: config.biosShopToken
    //     },
    //     body: JSON.stringify({ webShopOrder: order, printBill: order.printBill, printRecipe: order.printRecipe})
    // };
    // return function (dispatch) {
    //     return fetchWithSessionHandling(`${config.apiUrl}/shop/postOrder`, requestOptions).then((response) =>
    //         handleResponse(response)
    //     );
    // };
    // }
}

export function sendPaymentConfirmation(uid, keycloak) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/shop/sendPaymentConfirmation/${uid}`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getOrderStatusByOrderId(orderId) {
    if (orderId) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                shopIdentification: config.biosShopToken,
            },
        };
        return function (dispatch) {
            return fetch(`${config.apiUrl}/shop/getOrderStatusByOrderId/${orderId}`, requestOptions).then((response) =>
                handleResponse(response)
            );
        };
    }
}

export function getOrderStatusByUid(uid) {
    if (uid) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                shopIdentification: config.biosShopToken,
            },
        };
        return function (dispatch) {
            return fetch(`${config.apiUrl}/shop/getOrderStatusByUid/${uid}`, requestOptions).then((response) =>
                handleResponse(response)
            );
        };
    }
}

export const SET_ORDER = "SET_ORDER";

export function setOrder(order) {
    return {
        type: SET_ORDER,
        order: order,
    };
}
