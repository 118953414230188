import { Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Text, IconButton } from "react-native-paper";
import { View } from "react-native";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { Picker } from "@react-native-picker/picker";
import * as yup from "yup";

import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import { updateOnlineShopCustomer } from "../../store/actions/accountActions";
import { store } from "../../store/store";
import CustomInputField from "../../helpers/inputField/CustomInputField";
import { desktopBreakpoint, getAccessToken, getContrastColor } from "../../shared/helpers";
import { styles } from "../../shared/styles";
import { showToast, updateToast } from "../../helpers/toastMessage/ToastMessage";
import CustomerNavigation from "./CustomerNavigation";

const yupObject = yup.object().shape({
    phoneNumbers: yup.array().of(
        yup.object().shape({
            phonenumber: yup.string().when(["new", "markedForDeletion"], {
                is: (isNew, isMarkedForDeletion) => !(isNew && isMarkedForDeletion),
                then: yup
                    .string()
                    .required("Telefonnummer ist erforderlich")
                    .test("is-valid-phone", "Mindestens 7 Ziffern erforderlich", function (value) {
                        return value && value.length >= 7;
                    }),
                otherwise: yup.string(),
            }),
            new: yup.boolean(),
            markedForDeletion: yup.boolean(),
        })
    ),
});

const CustomerProfile = (props) => {
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [redirectToOrderDetails, setRedirectToOrderDetails] = useState(false);
    const formikRef = useRef();
    const location = useLocation();

    const getPhoneNumbersFromProps = () => {
        return props.account.customer.phonenumbers
            .filter((p) => !p.deleted)
            .map((phone, index) => ({
                ...phone,
                tempId: index,
            }));
    };

    useEffect(() => {
        const phoneNumbers = getPhoneNumbersFromProps();
        setPhoneNumbers(phoneNumbers);

        const searchParams = new URLSearchParams(location.search);
        const newRedirectToOrderDetails = searchParams.get("redirectToOrderDetails") === "true";
        if (newRedirectToOrderDetails !== redirectToOrderDetails) {
            setRedirectToOrderDetails(newRedirectToOrderDetails);
        }

        if (formikRef.current) {
            formikRef.current.setFieldValue("phoneNumbers", phoneNumbers);
        }
    }, [props.account.customer.phonenumbers, location.search, redirectToOrderDetails]);

    const handleSubmitForm = async (values, { setFieldValue, resetForm }) => {
        const { theme } = props.settings;

        const isProfileComplete = getMissingInfoText(values) === null;

        const toastId = showToast("Änderungen werden gespeichert", "information-circle-outline", "info");

        const updatedPhoneNumbers = values.phoneNumbers
            .filter((phone) => !(phone.new && phone.markedForDeletion && !phone.phonenumber.trim()))
            .map((phone) => ({
                phoneNumbersId: phone.new ? undefined : phone.phoneNumbersId,
                phonenumber: phone.phonenumber,
                new: phone.new || false,
                deleted: phone.markedForDeletion || false,
            }))
            .filter((phone) => phone.phonenumber.trim().length > 0 || phone.deleted);

        try {
            const response = await store.dispatch(
                updateOnlineShopCustomer(
                    values.title,
                    values.firstName,
                    values.familyName,
                    updatedPhoneNumbers,
                    getAccessToken()
                )
            );

            if (response.customerCourses instanceof Response && !response.customerCourses.ok) {
                updateToast(
                    toastId,
                    "Ein Fehler ist aufgetreten beim Aktualisieren der Kundendaten",
                    "alert-circle-outline",
                    "error"
                );

                await props.update();

                // Refresh the customer data after a delay to ensure the update is complete
                setTimeout(() => {
                    const refreshedCustomer = props.account.customer;
                    const refreshedPhoneNumbers = getPhoneNumbersFromProps();

                    setPhoneNumbers(refreshedPhoneNumbers);
                    if (formikRef.current) {
                        formikRef.current.resetForm({
                            values: {
                                title: refreshedCustomer.title || "",
                                firstName: refreshedCustomer.firstName,
                                familyName: refreshedCustomer.familyName,
                                email: refreshedCustomer.email,
                                phoneNumbers: refreshedPhoneNumbers,
                            },
                        });
                    }
                }, 2000);
            } else {
                updateToast(
                    toastId,
                    "Änderungen wurden erfolgreich gespeichert",
                    "checkmark-circle-outline",
                    "success"
                );

                props.update();

                const newPhoneNumbers = values.phoneNumbers
                    .filter((phone) => !phone.deleted)
                    .map((phone) => ({
                        ...phone,
                        new: false,
                        phoneNumbersId: phone.new ? response.phoneNumbersId : phone.phoneNumbersId,
                    }));
                setFieldValue("phoneNumbers", newPhoneNumbers);
                setPhoneNumbers(newPhoneNumbers);

                if (redirectToOrderDetails && isProfileComplete) {
                    props.toggleCustomerData();
                }
            }
        } catch (error) {
            console.error("Fehler beim Aktualisieren oder Neuladen der Kundendaten:", error);
        }
    };

    const handleAddPhoneNumber = (values, setFieldValue, setFieldTouched) => {
        const newPhoneNumbers = [
            ...values.phoneNumbers,
            {
                phoneNumbersId: undefined,
                phonenumber: "",
                new: true,
                deleted: false,
                markedForDeletion: false,
                tempId: Date.now() + Math.random(),
            },
        ];
        setFieldValue("phoneNumbers", newPhoneNumbers, false);
        const newIndex = newPhoneNumbers.length - 1;
        setFieldTouched(`phoneNumbers[${newIndex}].phonenumber`, true, true);
    };

    const handlePhoneNumberChange = (tempId, value, values, setFieldValue, setFieldTouched, setFieldError) => {
        const numericValue = value.replace(/[^0-9]/g, "");
        const newPhoneNumbers = values.phoneNumbers.map((phone) =>
            phone.tempId === tempId ? { ...phone, phonenumber: numericValue } : phone
        );
        setFieldValue("phoneNumbers", newPhoneNumbers, false);
        const index = newPhoneNumbers.findIndex((phone) => phone.tempId === tempId);
        setFieldTouched(`phoneNumbers[${index}].phonenumber`, true, false);

        if (numericValue.length === 0 || numericValue.length >= 7) {
            setFieldError(`phoneNumbers[${index}].phonenumber`, undefined);
        } else {
            setFieldError(`phoneNumbers[${index}].phonenumber`, "Mindestens 7 Ziffern erforderlich");
        }
    };

    const handleDeletePhoneNumber = (tempId, phoneNumbersId, values, setFieldValue) => {
        const newPhoneNumbers = values.phoneNumbers
            .map((phone) => {
                if (phone.tempId === tempId || (phoneNumbersId && phone.phoneNumbersId === phoneNumbersId)) {
                    if (phone.new && !phone.phonenumber.trim()) {
                        return null;
                    }
                    return { ...phone, markedForDeletion: !phone.markedForDeletion };
                }
                return phone;
            })
            .filter((phone) => phone !== null);
        setFieldValue("phoneNumbers", newPhoneNumbers, false);
    };

    const getMissingInfoText = (values) => {
        let missingInfo = [];

        if (!values.title) {
            missingInfo.push("Ihre Anrede");
        }
        if (!values.firstName) {
            missingInfo.push("Ihren Vorname");
        }
        if (!values.familyName) {
            missingInfo.push("Ihren Nachname");
        }
        if (values.phoneNumbers.every((phone) => !phone.phonenumber)) {
            missingInfo.push("Ihre Telefonnummer");
        }

        if (missingInfo.length === 0) {
            return null;
        }

        return `Wenn Sie eine Bestellung tätigen möchten, vervollständigen Sie bitte Ihr Profil.\nFolgende Angaben fehlen aktuell: \n \n* ${missingInfo.join(
            "\n* "
        )}.`;
    };

    const customer = props.account.customer;
    const { theme } = props.settings;
    const { windowWidth } = props;
    const isDesktop = windowWidth >= desktopBreakpoint;

    return (
        <View style={[isDesktop ? styles.desktopContainerWrapper : null, { padding: !isDesktop ? 20 : null }]}>
            <View>
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <CustomIconButton
                        icon="arrow-left"
                        tooltip="Zurück"
                        position="right"
                        onPress={() => props.history.push("/")}
                    ></CustomIconButton>
                    <Text style={{ marginTop: 17 }}>Zurück zur Startseite</Text>
                </View>
                <View
                    nativeID="customer-area"
                    style={[styles.customerArea, isDesktop ? styles.customerAreaDesktop : styles.customerAreaMobile]}
                >
                    <View
                        nativeID="customer-navigation"
                        style={[
                            styles.customerNavigation,
                            isDesktop ? styles.customerNavigationDesktop : styles.customerNavigationMobile,
                        ]}
                    >
                        <CustomerNavigation windowWidth={windowWidth} />
                    </View>
                    <View
                        nativeID="customer-content"
                        style={[
                            styles.customerContent,
                            isDesktop ? styles.customerContentDesktop : styles.customerContentMobile,
                        ]}
                    >
                        <View
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Card style={{ width: 350, borderRadius: 13, marginBottom: 50 }}>
                                <Formik
                                    innerRef={formikRef}
                                    initialValues={{
                                        title: customer.title || "",
                                        firstName: customer.firstName,
                                        familyName: customer.familyName,
                                        email: customer.email,
                                        phoneNumbers: phoneNumbers,
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={yupObject}
                                    onSubmit={handleSubmitForm}
                                    validateOnChange={false}
                                    validateOnBlur={true}
                                >
                                    {(formikProps) => {
                                        const {
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            values,
                                            errors,
                                            touched,
                                            setFieldValue,
                                            setFieldTouched,
                                            setFieldError,
                                        } = formikProps;
                                        return (
                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: 30,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        styles.profilEditSelectInfoText,
                                                        { color: "red", marginLeft: 20, textAlign: "start" },
                                                    ]}
                                                >
                                                    {getMissingInfoText(values)}
                                                </Text>
                                                <Text style={styles.profilEditSelectLabel}>Anrede</Text>
                                                <Picker
                                                    selectedValue={values.title}
                                                    onValueChange={(itemValue) => setFieldValue("title", itemValue)}
                                                    style={[
                                                        styles.profilEditSelect,
                                                        {
                                                            backgroundColor: theme.colors.surface,
                                                            borderColor: getContrastColor(theme.colors.surface),
                                                            borderWidth: 1,
                                                            color: getContrastColor(theme.colors.surface),
                                                            fontFamily: theme.fonts.regular.fontFamily,
                                                            fontSize: 17,
                                                            marginBottom: 10,
                                                            paddingVertical: 15,
                                                            width: 230,
                                                        },
                                                    ]}
                                                >
                                                    <Picker.Item label="- bitte auswählen -" value="" />
                                                    <Picker.Item label="Herr" value="Herr" />
                                                    <Picker.Item label="Frau" value="Frau" />
                                                    <Picker.Item label="Divers" value="Divers" />
                                                </Picker>

                                                <CustomInputField
                                                    label="Vorname"
                                                    onChange={handleChange("firstName")}
                                                    value={values.firstName}
                                                    style={{ backgroundColor: theme.colors.surface, width: 230 }}
                                                />

                                                <CustomInputField
                                                    label="Nachname"
                                                    onChange={handleChange("familyName")}
                                                    value={values.familyName}
                                                    style={{ backgroundColor: theme.colors.surface, width: 230 }}
                                                />

                                                <CustomInputField
                                                    label="E-Mail"
                                                    disabled={true}
                                                    type="email"
                                                    onChange={handleChange("email")}
                                                    value={values.email}
                                                    style={{ width: 230 }}
                                                />

                                                <Text style={{ marginVertical: 10 }}>Telefonnummern</Text>
                                                {values.phoneNumbers
                                                    .filter((phone) => !phone.deleted)
                                                    .map((phone, index) => (
                                                        <View
                                                            key={phone.tempId || phone.phoneNumbersId}
                                                            style={{
                                                                flexDirection: "row",
                                                                alignItems: "left",
                                                                marginVertical: 5,
                                                                width: 230,
                                                            }}
                                                        >
                                                            <View
                                                                style={{
                                                                    flexDirection: "column",
                                                                    alignItems: "left",
                                                                }}
                                                            >
                                                                <CustomInputField
                                                                    label={`Telefonnummer ${index + 1}`}
                                                                    onChange={(text) =>
                                                                        handlePhoneNumberChange(
                                                                            phone.tempId,
                                                                            text,
                                                                            values,
                                                                            setFieldValue,
                                                                            setFieldTouched,
                                                                            setFieldError
                                                                        )
                                                                    }
                                                                    onBlur={() => {
                                                                        handleBlur(
                                                                            `phoneNumbers[${index}].phonenumber`
                                                                        )();
                                                                        if (
                                                                            phone.phonenumber.length > 0 &&
                                                                            phone.phonenumber.length < 7
                                                                        ) {
                                                                            setFieldError(
                                                                                `phoneNumbers[${index}].phonenumber`,
                                                                                "Mindestens 7 Ziffern erforderlich"
                                                                            );
                                                                        }
                                                                    }}
                                                                    value={phone.phonenumber}
                                                                    keyboardType="numeric"
                                                                    error={
                                                                        touched.phoneNumbers &&
                                                                        touched.phoneNumbers[index] &&
                                                                        touched.phoneNumbers[index].phonenumber &&
                                                                        errors.phoneNumbers &&
                                                                        errors.phoneNumbers[index] &&
                                                                        errors.phoneNumbers[index].phonenumber
                                                                    }
                                                                    helperText={
                                                                        phone.markedForDeletion
                                                                            ? "zur Löschung markiert"
                                                                            : touched.phoneNumbers &&
                                                                              touched.phoneNumbers[index] &&
                                                                              touched.phoneNumbers[index].phonenumber &&
                                                                              errors.phoneNumbers &&
                                                                              errors.phoneNumbers[index] &&
                                                                              errors.phoneNumbers[index].phonenumber
                                                                            ? errors.phoneNumbers[index].phonenumber
                                                                            : ""
                                                                    }
                                                                    style={{
                                                                        backgroundColor: phone.markedForDeletion
                                                                            ? theme.colors.error
                                                                            : theme.colors.surface,
                                                                        width: 200,
                                                                    }}
                                                                    textColor={
                                                                        phone.markedForDeletion
                                                                            ? getContrastColor(theme.colors.error)
                                                                            : getContrastColor(theme.colors.surface)
                                                                    }
                                                                    labelColor={
                                                                        phone.markedForDeletion
                                                                            ? getContrastColor(theme.colors.error)
                                                                            : getContrastColor(theme.colors.surface)
                                                                    }
                                                                    disabled={phone.markedForDeletion}
                                                                />
                                                            </View>
                                                            <IconButton
                                                                icon={phone.markedForDeletion ? "undo" : "delete"}
                                                                onPress={() => {
                                                                    handleDeletePhoneNumber(
                                                                        phone.tempId,
                                                                        phone.phoneNumbersId,
                                                                        values,
                                                                        setFieldValue
                                                                    );
                                                                }}
                                                            />
                                                        </View>
                                                    ))}
                                                <Button
                                                    mode="contained"
                                                    textColor={getContrastColor(theme.colors.primary)}
                                                    style={{
                                                        backgroundColor: theme.colors.primary,
                                                        textTransform: "none",
                                                        marginTop: 10,
                                                        marginBottom: 0,
                                                        width: 230,
                                                    }}
                                                    onPress={() =>
                                                        handleAddPhoneNumber(values, setFieldValue, setFieldTouched)
                                                    }
                                                >
                                                    Telefonnummer hinzufügen
                                                </Button>

                                                <Button
                                                    type="submit"
                                                    onPress={handleSubmit}
                                                    textColor={getContrastColor(theme.colors.primary)}
                                                    style={{
                                                        backgroundColor: theme.colors.primary,
                                                        textTransform: "none",
                                                        marginTop: 50,
                                                        marginBottom: 50,
                                                        width: 230,
                                                    }}
                                                    uppercase={false}
                                                >
                                                    Änderungen speichern
                                                </Button>
                                            </View>
                                        );
                                    }}
                                </Formik>
                            </Card>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withRouter(CustomerProfile));
