import { combineReducers } from "redux";
import onlineShopCategories from "./onlineShopCategoriesReducer";
import settings from "./settingsReducer";
import account from "./accountReducer";
import cart from "./cartReducer";
import order from "./orderReducer";
import orderStatus from "./orderStatusReducer";
import uiReducer from "./uiReducer";
import gastReducer from "./gastReducer";
const rootReducer = combineReducers({
    onlineShopCategories,
    settings,
    account,
    cart,
    order,
    orderStatus,
    uiReducer,
    gast: gastReducer,
});

export default rootReducer;
