import React, { Component } from "react";
import { Avatar, Card, Text, withTheme } from "react-native-paper";
import { View } from "react-native-web";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import { getContrastColor } from "../../shared/helpers";
import { store } from "../../store/store";
import AddAddressPopup from "./AddAddressPopup";
import { Dimensions } from "react-native";

class CustomerCheckoutAddAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
            showAddAddressPopup: false,
            test: false,
            additionalAddressToEdit: 0,
            edit: false,
            showConfirmDeletePopup: false,
            addressToDelete: undefined,
            indexToDelete: 0,
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.toggleAddAddressPopup = this.toggleAddAddressPopup.bind(this);
        this.editAddress = this.editAddress.bind(this);
    }

    componentDidMount() {
        const { customer } = this.props.account;
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    toggleAddAddressPopup() {
        if (this.state.edit && this.state.showAddAddressPopup) this.setState({ edit: false });
        document.body.style.overflow = !this.state.showAddAddressPopup ? "hidden" : "auto";
        this.setState({ showAddAddressPopup: !this.state.showAddAddressPopup });
    }
    editAddress(address) {
        const { customer } = this.props.account;
        this.setState(
            {
                additionalAddressToEdit: address,
                indexOfAddressToEdit: customer.addresses.indexOf(address),
                edit: true,
            },
            () => {
                this.toggleAddAddressPopup();
            }
        );
    }

    render() {
        const { customer } = this.props.account;
        const { theme } = this.props;
        const { windowWidth } = this.state;
        return (
            <React.Fragment>
                <Card
                    style={{
                        backgroundColor: "transparent",
                        borderWidth: 3,
                        borderStyle: "dashed",
                        borderColor: theme.colors.primary,

                        width: windowWidth <= 768 ? "calc(100% - 10px)" : "calc(33% - 10px)",
                        display: "flex",
                        margin: 5,
                        cursor: "pointer",
                    }}
                    onPress={() => this.toggleAddAddressPopup()}
                >
                    <Card.Content style={{ margin: "auto" }}>
                        <View
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Avatar.Icon
                                icon="plus"
                                size={38}
                                style={{
                                    backgroundColor: theme.colors.primary,
                                }}
                                color={getContrastColor(theme.colors.primary)}
                            />
                            <Text style={{ color: theme.colors.text, marginTop: 15 }}>Adresse hinzufügen</Text>
                        </View>
                    </Card.Content>
                </Card>

                {this.state.showAddAddressPopup && (
                    <AddAddressPopup
                        visible={this.state.showAddAddressPopup}
                        togglePopup={this.toggleAddAddressPopup}
                        numberOfAddressesSaved={customer.addresses.length}
                        addressIndex={this.state.indexOfAddressToEdit}
                        addressToEdit={this.state.additionalAddressToEdit}
                        edit={this.state.edit}
                        theme={theme}
                        update={() => {
                            this.props.update();
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withTheme(withRouter(CustomerCheckoutAddAddress)));
