import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dimensions } from "react-native";
import * as yup from "yup";

import Config from "../../Config";

const config = new Config();

export const desktopBreakpoint = 1100;
export const phabletBreakpoint = 600;
export const tabletBreakpoint = 376;

export function useWindowSize() {
    const [size, setSize] = useState([Dimensions.get("window").width, Dimensions.get("window").height]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([Dimensions.get("window").width, Dimensions.get("window").height]);
        }
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

export function filterOnlineShopItems(onlineShopItems, searchText) {
    if (!onlineShopItems) return [];
    if (searchText === "") return onlineShopItems;
    return onlineShopItems.filter((item) => {
        if (searchText) {
            return (
                item.name.toLowerCase().includes(searchText.toLowerCase()) ||
                item.categories.filter((category) => category.toLowerCase().includes(searchText.toLowerCase())).length >
                    0
            );
        }
        return true;
    });
}

export const withHooksHOC = (Component) => (props) => {
    const windowSize = useWindowSize();

    return <Component windowSize={windowSize} {...props} />;
};

export const withLocation = (Component) => {
    return (props) => {
        const location = useLocation();
        return <Component {...props} location={location} />;
    };
};

// helper function for centralized token access
export const getAccessToken = () => {
    return localStorage.getItem("accessToken") || "";
};

export function createYupSchema(schema, config, isLoggedIn) {
    const { id, validationType, validations = [], showWhenLoggedIn, showWhenNotLoggedIn } = config;

    if ((isLoggedIn && !showWhenLoggedIn) || (!isLoggedIn && !showWhenNotLoggedIn)) {
        return schema;
    }

    if (!yup[validationType]) {
        return schema;
    }

    let validator = yup[validationType]();
    validations.forEach((validation) => {
        const { params, type } = validation;
        if (!validator[type]) {
            return;
        }
        validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
}

export function updateCountryNames(customerData, countries) {
    const getCountryNameById = (countryId) => {
        const country = countries.find((country) => country.countryId.toString() === countryId.toString());
        return country ? country.name : null;
    };

    if (customerData.country) {
        customerData.country = getCountryNameById(customerData.country);
    }

    if (customerData.billingCountry) {
        customerData.billingCountry = getCountryNameById(customerData.billingCountry);
    }

    return customerData;
}

export function getCountryByName(name, countries) {
    return countries.find((country) => country.name === name);
}

export function findPriceBySelectedArticleSize(
    articleSizes = [],
    orderTypesId = -1,
    priceGroupsId = -1,
    articleSizesId = -1
) {
    if (!Array.isArray(articleSizes)) {
        console.error("Invalid articleSizes array");
        return {
            price: "Invalid articleSizes array",
            portionSize: "Invalid input",
        };
    }

    const numericOrderTypesId = Number(orderTypesId);
    const numericPriceGroupsId = Number(priceGroupsId);
    const numericArticleSizesId = Number(articleSizesId);

    for (let articleSize of articleSizes) {
        if (articleSize.articleSizesId === numericArticleSizesId) {
            for (let price of articleSize.Prices) {
                if (price.orderTypesId === numericOrderTypesId && price.priceGroupsId === numericPriceGroupsId) {
                    return {
                        price: price.price1,
                        portionSize: articleSize.portionSize,
                    };
                }
            }
        }
    }

    return {
        price: "Preis nicht gefunden",
        portionSize: "Portionsgröße nicht gefunden",
    };
}

// export function checkIfArticleSizeHasPrice(articleSize, orderTypesId, priceGroupsId) {
//     if (getPriceOfArticleSize(articleSize, orderTypesId, priceGroupsId)) {
//         return true;
//     }

//     return false;
// }

export function getPriceOfArticleSize(articleSize, orderTypesId, priceGroupsId) {
    var price = articleSize.Prices.find(
        (price) => price.orderTypesId === orderTypesId && price.priceGroupsId === priceGroupsId
    );
    return price;
}

export function checkIfArticleSizeHasPrices(articleSize, orderTypesId, priceGroupsId) {
    if (!articleSize.Size || articleSize.Size.isDefault) return false;
    if (articleSize.Prices.length === 0) return false;
    return articleSize.Prices.find(
        (price) => price.orderTypesId === orderTypesId && price.priceGroupsId === priceGroupsId
    );
}

/**
 * Create a safe and readable URL
 */
export function createReadableAndSafeURL(name = "") {
    const cleanedName = name
        .trim()
        .replace(/ä/g, "ae")
        .replace(/ö/g, "oe")
        .replace(/ü/g, "ue")
        .replace(/ß/g, "ss")
        .replace(/#/g, "")
        .replace(/\//g, "-")
        .replace(/\\/g, "-")
        .replace(/\?/g, "")
        .replace(/=/g, "-")
        .replace(/&/g, "und")
        .replace(/[.,:;!?()<>{}[\]\\]/g, "")
        .replace(/\s+/g, "-")
        .replace(/\$/g, "")
        .replace(/%/g, "")
        .replace(/'/g, "")
        .replace(/\s+/g, "-")
        .replace(/"/g, "")
        .replace(/`/g, "")
        .replace(/--+/g, "-")
        .toLowerCase();

    return encodeURIComponent(cleanedName);
}

/**
 * Format number to local string, needed for german number format
 */
export const formatNumberToLocalString = (value) => {
    return value.toLocaleString("de-DE");
};

/**
 * Converts a value from one unit to another.
 * @param {number} value - The value to be converted.
 * @param {string} fromUnit - The unit to convert from.
 * @param {string} toUnit - The unit to convert to.
 * @returns {Array} - An array containing the converted value and the new unit.
 */
export function convertUnit(value = 1, fromUnit = "mg", toUnit = "g") {
    let convertedValue;

    switch (fromUnit + "_" + toUnit) {
        case "g_kg":
            convertedValue = value / 1000;
            break;
        case "mg_g":
            convertedValue = value / 1000;
            break;
        case "kg_g":
            convertedValue = value * 1000;
            break;
        case "g_mg":
            convertedValue = value * 1000;
            break;
        case "l_ml":
            convertedValue = value * 1000;
            break;
        case "ml_l":
            convertedValue = value / 1000;
            break;
        default:
            console.warn(`Conversion from ${fromUnit} to ${toUnit} is not defined.`);
            return [value, fromUnit];
    }

    return [convertedValue, toUnit];
}

/**
 * Checks if there are any recipes available, needed for navigation
 */
export function checkForRecipes(instances = [], onlineShopCategories = {}) {
    let showRecipes = false;
    instances.push({ url: config.backendHost });
    instances.forEach((instance) => {
        if (onlineShopCategories["recipes_" + instance.url]) {
            const category = onlineShopCategories["recipes_" + instance.url][0];

            if (category && Array.isArray(category)) {
                if (
                    category.filter((category) => category.OnlineShopItems && category.OnlineShopItems.length > 0)
                        .length > 0
                ) {
                    showRecipes = true;
                }
            }
        }
    });
    return showRecipes;
}

//* Get readable color */
export function getContrastColor(backgroundHexColor) {
    const hexToRgb = (hex) => ({
        r: parseInt(hex.substr(1, 2), 16),
        g: parseInt(hex.substr(3, 2), 16),
        b: parseInt(hex.substr(5, 2), 16),
    });

    const rgbToLuminance = (rgb) => {
        const sRgb = (c) => {
            const v = c / 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        };
        return 0.2126 * sRgb(rgb.r) + 0.7152 * sRgb(rgb.g) + 0.0722 * sRgb(rgb.b);
    };

    const backgroundRgb = hexToRgb(backgroundHexColor);
    const backgroundLuminance = rgbToLuminance(backgroundRgb);

    const getContrastColorBasedOnLuminance = (luminance) => {
        return luminance > 0.179 ? "#000000" : "#FFFFFF";
    };

    return getContrastColorBasedOnLuminance(backgroundLuminance);
}

/** Get inverted Color */
// export function getContrastColor(hex) {
//     if (hex[0] === "#") {
//         hex = hex.slice(1);
//     }

//     const r = parseInt(hex.slice(0, 2), 16);
//     const g = parseInt(hex.slice(2, 4), 16);
//     const b = parseInt(hex.slice(4, 6), 16);

//     const invertedR = 255 - r;
//     const invertedG = 255 - g;
//     const invertedB = 255 - b;

//     const invertedColor = "#" + ((1 << 24) + (invertedR << 16) + (invertedG << 8) + invertedB).toString(16).slice(1);

//     return invertedColor;
// }

/** get Complementary Color */
// function RGBtoHSL(rgb) {
//     let r = rgb[0] / 255;
//     let g = rgb[1] / 255;
//     let b = rgb[2] / 255;
//     let max = Math.max(r, g, b);
//     let min = Math.min(r, g, b);
//     let h,
//         s,
//         l = (max + min) / 2;

//     if (max === min) {
//         h = s = 0;
//     } else {
//         let d = max - min;
//         s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
//         switch (max) {
//             case r:
//                 h = (g - b) / d + (g < b ? 6 : 0);
//                 break;
//             case g:
//                 h = (b - r) / d + 2;
//                 break;
//             case b:
//                 h = (r - g) / d + 4;
//                 break;
//         }
//         h /= 6;
//     }
//     return [h, s, l];
// }

// function HSLtoRGB(hsl) {
//     let h = hsl[0];
//     let s = hsl[1];
//     let l = hsl[2];
//     let r, g, b;

//     if (s === 0) {
//         r = g = b = l; // achromatic
//     } else {
//         function hue2rgb(p, q, t) {
//             if (t < 0) t += 1;
//             if (t > 1) t -= 1;
//             if (t < 1 / 6) return p + (q - p) * 6 * t;
//             if (t < 1 / 2) return q;
//             if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
//             return p;
//         }

//         let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
//         let p = 2 * l - q;
//         r = hue2rgb(p, q, h + 1 / 3);
//         g = hue2rgb(p, q, h);
//         b = hue2rgb(p, q, h - 1 / 3);
//     }
//     return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
// }

// export function getContrastColor(hexColor) {
//     let r = parseInt(hexColor.slice(1, 3), 16);
//     let g = parseInt(hexColor.slice(3, 5), 16);
//     let b = parseInt(hexColor.slice(5, 7), 16);

//     let hsl = RGBtoHSL([r, g, b]);

//     hsl[0] = (hsl[0] + 0.5) % 1;

//     let rgb = HSLtoRGB(hsl);

//     return (
//         "#" +
//         rgb
//             .map((x) => {
//                 let hex = x.toString(16);
//                 return hex.length === 1 ? "0" + hex : hex;
//             })
//             .join("")
//     );
// }
