import { DefaultTheme } from "react-native-paper";
import { SET_SETTINGS, SET_THEME, SET_SEARCHTEXT } from "../actions/settingsActions";

const initialState = {
    searchText: "",
    store: {
        // obsolete, use the maintainable variable this.props.settings.store.maxPortionNumber instead
        maxSingleArticleAmount: 9,
    },
    customerData: {
        fields: [
            {
                id: "title",
                label: "",
                placeholder: "Anrede auswählen",
                type: "select",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "firstName",
                label: "",
                placeholder: "Vorname",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "lastName",
                label: "",
                placeholder: "Nachname",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "email",
                label: "",
                placeholder: "E-Mail-Adresse",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                    {
                        type: "email",
                        params: ["Keine korrekte E-Mail-Adresse"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "phoneNumber",
                label: "",
                placeholder: "Telefonnummer",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "txtStreet",
                label: "",
                placeholder: "Straße",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "housenumber",
                label: "",
                placeholder: "Hausnummer",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "txtZipCode",
                label: "",
                placeholder: "PLZ",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "txtCity",
                label: "",
                placeholder: "Stadt",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "country",
                label: "",
                placeholder: "Land",
                type: "select",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },

            {
                id: "comment",
                label: "",
                placeholder: "Kommentar/Wünsche",
                type: "textarea",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: false,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: true,
            },
            {
                id: "deliveryAddress",
                label: "",
                placeholder: "Lieferadresse auswählen",
                type: "selectAddress",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: false,
            },
            {
                id: "useDifferentBillingAddress",
                label: "",
                placeholder: "abweichende Rechnungsadresse verwenden",
                type: "useDifferentBillingAddress",
                validationType: "bool",
                value: false,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: false,
            },

            {
                id: "billingTitle",
                label: "",
                placeholder: "Anrede auswählen",
                type: "select",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingFirstName",
                label: "",
                placeholder: "Vorname",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingLastName",
                label: "",
                placeholder: "Nachname",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingStreet",
                label: "",
                placeholder: "Straße",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingHousenumber",
                label: "",
                placeholder: "Hausnummer",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingZipCode",
                label: "",
                placeholder: "PLZ",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingCity",
                label: "",
                placeholder: "Stadt",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingCountry",
                label: "",
                placeholder: "Land",
                type: "select",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingAddress",
                label: "",
                placeholder: "Rechnungsadresse auswählen",
                type: "selectAddress",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: false,
            },

            // not needed for now
            // {
            //     id: "printBill",
            //     label: "",
            //     placeholder: "Rechnung drucken",
            //     type: "printBillCheckbox",
            //     validationType: "boolean",
            //     validations: [],
            //     value: "",
            //     showInRegistration: false,
            //     showWhenLoggedIn: true,
            //     showWhenNotLoggedIn: true,
            // },
            {
                id: "acceptTerms",
                label: "",
                placeholder: "Datenschutz/AGB akzeptieren",
                type: "checkbox",
                validationType: "boolean",
                validations: [
                    {
                        type: "required",
                        params: ["Sie müssen die AGB/den Datenschutz akzeptieren"],
                    },
                    {
                        type: "isTrue",
                        params: ["Sie müssen die AGB/den Datenschutz akzeptieren"],
                    },
                ],
                value: "",
                showInRegistration: false,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: true,
            },
            {
                id: "paymentTypesId",
                label: "",
                placeholder: "Zahlungsart",
                type: "select",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: false,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: true,
            },
            // {
            //     id: "printRecipe",
            //     label: "",
            //     placeholder: "Rezept drucken",
            //     type: "printRecipeCheckbox",
            //     validationType: "boolean",
            //     validations: [],
            //     value: "",
            //     showInRegistration: false,
            //     showWhenLoggedIn: true,
            //     showWhenNotLoggedIn: true,
            // },
        ],
    },
    theme: {
        ...DefaultTheme,
        logoUrl: "",
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS:
            if (action.store) {
                delete action.store.onlineShopClientId;
                delete action.store.signUpUserFlowAuthority;
                delete action.store.authorityDomain;
            }
            return Object.assign({}, state, {
                store: { ...state.store, ...action.store },
            });
        case SET_THEME:
            return Object.assign({}, state, {
                theme: { ...action.theme },
            });
        case SET_SEARCHTEXT:
            return Object.assign({}, state, {
                searchText: action.searchText,
            });
        default:
            return state;
    }
};
