import React, { useState, useEffect } from "react";
import { View, Pressable, Text } from "react-native";
import { RadioButton } from "react-native-paper";
import { styles } from "../../../shared/styles";
import { customerDataStyles } from "../CustomerDataStyles";

const TitleRadioButtons = ({ field, props, error, customer, handleGastDataSave, localGuestData }) => {
    const [checked, setChecked] = useState(() => {
        // Prüfen Sie zuerst localGuestData, dann props.values, und verwenden Sie "Herr" als Fallback
        return localGuestData[field.id] || props.values[field.id] || "Herr";
    });

    useEffect(() => {
        if (!checked) {
            const initialValue = "Herr";
            setChecked(initialValue);
            props.setFieldValue(field.id, initialValue);
            handleGastDataSave(field.id, initialValue);
        }
    }, []);

    const handleRadioChange = (value) => {
        props.setFieldValue(field.id, value);
        setChecked(value);
        handleGastDataSave(field.id, value);
    };

    if (customer) return null;

    return (
        <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
            <Text>Anrede</Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                {["Herr", "Frau", "Divers"].map((option) => (
                    <Pressable key={option} onPress={() => handleRadioChange(option)}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                            <RadioButton.IOS
                                value={option}
                                status={checked === option ? "checked" : "unchecked"}
                                onPress={() => handleRadioChange(option)}
                            />
                            <Text>{option}</Text>
                        </View>
                    </Pressable>
                ))}
            </View>
            {error && (
                <View>
                    <Text style={styles.pickerError}>{props.errors[field.id] ? props.errors[field.id] : ""}</Text>
                </View>
            )}
        </View>
    );
};

export default TitleRadioButtons;
