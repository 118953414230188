import React from "react";
import { View } from "react-native";
import { Dialog } from "react-native-paper";
import { Formik, Form } from "formik";
import { customerDataStyles } from "../CustomerDataStyles";
import Summary from "./Summary";

const DialogContent = ({
    theme,
    initialValues,
    validateSchema,
    settings,
    customerDataForm,
    formElements,
    PaymentForm,
    windowWidth,
    windowHeight,
    step,
    handleSubmit,
    setPaymentTypesId,
    setFormIsValid,
}) => {
    return (
        <Dialog.Content
            style={{
                backgroundColor: theme.colors.surface,
                padding: 0,
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingHorizontal: 0,
            }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validateSchema}
                onSubmit={(values) => {
                    const selectedPaymentType = settings.store.availablePaymentTypes.find(
                        (type) => type.paymentTypesId === values.paymentTypesId
                    );

                    if (!selectedPaymentType) {
                        console.error("Invalid payment type");
                        return;
                    }

                    const paymentTypesId = selectedPaymentType?.paymentTypesId;

                    const payload = {
                        ...values,
                        billingAddress: values.useDifferentBillingAddress
                            ? values.billingAddress
                            : values.deliveryAddress,
                    };
                    handleSubmit(payload, paymentTypesId);
                }}
                innerRef={customerDataForm}
            >
                {(formikProps) => {
                    const { isValid } = formikProps;

                    React.useEffect(() => {
                        setFormIsValid(isValid);
                    }, [isValid, setFormIsValid]);

                    return (
                        <Form>
                            <View style={customerDataStyles.fieldsWrapper}>
                                <View
                                    style={{
                                        display: step === 0 ? "flex" : "none",
                                        width: "100%",
                                        flexWrap: "wrap",
                                        flexDirection: "row",
                                    }}
                                >
                                    {formElements({ ...formikProps })}
                                </View>
                                <View
                                    style={{
                                        display: step === 1 ? "block" : "none",
                                        width: "100%",
                                    }}
                                >
                                    <PaymentForm
                                        formProps={formikProps}
                                        windowWidth={windowWidth}
                                        theme={theme}
                                        setPaymentTypesId={setPaymentTypesId}
                                    />
                                </View>
                                <View
                                    style={{
                                        display: step === 2 ? "block" : "none",
                                        width: "100%",
                                    }}
                                >
                                    <Summary props={formikProps} theme={theme} settings={settings} />
                                </View>
                            </View>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog.Content>
    );
};

export default DialogContent;
