import { StyleSheet } from "react-native";

export const orderHistoryStyles = StyleSheet.create({
    container: {
        display: "flex",
        flexGrow: 1,
    },
    desktopContainer: {
        flexDirection: "row",
    },
    mobileContainer: {
        flexDirection: "column",
    },
    contentContainer: {
        flex: 1,
    },
    backButtonContainer: {
        display: "flex",
        flexDirection: "row",
    },
    backButtonText: {
        marginTop: 17,
    },
    contentTableContainer: {
        marginLeft: 20,
    },
    tableDisabledCursor: {
        cursor: "default",
    },
});
