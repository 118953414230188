import React, { Component } from "react";
import { Dimensions, Linking, Platform, View } from "react-native";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Config from "../../Config";
import CustomerData from "../screens/customerScreens/customerData/CustomerData";
import {
    desktopBreakpoint,
    filterOnlineShopItems,
    updateCountryNames,
    getCountryByName,
    getAccessToken,
} from "../shared/helpers";
import { postOrder } from "../store/actions/orderActions";
// import { createStripeSession, getPaymentLink } from "../store/actions/paymentActions";
/** Stripe deactivated for now  */
import { getPaymentLink } from "../store/actions/paymentActions";
import { store } from "../store/store";
import Footer from "./footer/Footer";
import CategoriesHeader from "./header/CategoriesHeader";
import Header from "./header/Header";
import SubHeader from "./header/SubHeader";
import { MainLayoutStyles } from "./MainLayoutStyles";
import Cart from "./sidebars/Cart";
import MobileMenu from "./sidebars/MobileMenu";
import FooterDesktop from "./footer/FooterDesktop";
import UpdateUserInformationPopup from "../screens/customerScreens/UpdateUserInformationPopup";
import ToastMessage from "../helpers/toastMessage/ToastMessage";
import _ from "lodash";

const config = new Config();

class MainLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTask: "",
            errorText: "",
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            recipesFound: false,
            biosInstances: [],
            userInformationMissingVisible: false,
        };

        this.createOrder = this.createOrder.bind(this);
        this.handleDoOrder = this.handleDoOrder.bind(this);
        this.redirectCountdown = this.redirectCountdown.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.updateCategories = this.updateCategories.bind(this);
        this.toggleCustomerData = this.toggleCustomerData.bind(this);
        this.toggleUserInformationPopup = this.toggleUserInformationPopup.bind(this);
        this.headerHeight = "0px";
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.onlineShopCategories.onlineShopItemType !== this.props.onlineShopCategories.onlineShopItemType) {
            this.updateCategories();
        }
        if (this.headerHeight !== this.prevHeaderHeight) {
            this.props.updateHeaderHeight(this.headerHeight);
            this.prevHeaderHeight = this.headerHeight;
        }

        // Überprüfen Sie den Token-Status und starten Sie das Intervall neu
        if (this.props.isAuthenticated) {
            this.props.setTokenCheckInterval();
        } else {
            this.props.clearTokenCheckInterval();
        }
    }

    newHeaderHeight(headerHeight) {
        if (this.headerHeight !== this.prevHeaderHeight) {
            this.props.updateHeaderHeight(this.headerHeight);
            this.prevHeaderHeight = this.headerHeight;
        }
        this.headerHeight = headerHeight;
    }

    updateCategories() {
        let biosInstances = [];
        this.setState({ biosInstances: biosInstances });
        var categories = [];
        biosInstances.push({ url: config.backendHost });
        biosInstances.forEach((instance) => {
            if (
                this.props.onlineShopCategories["recipes_" + instance.url] &&
                this.props.onlineShopCategories["recipes_" + instance.url][0]
            ) {
                this.props.onlineShopCategories["recipes_" + instance.url][0].forEach((category) => {
                    if (
                        category.showInOnlineShopHeader &&
                        (this.props.onlineShopCategories.onlineShopItemType === -1 ||
                            category.onlineShopItemType === this.props.onlineShopCategories.onlineShopItemType)
                    ) {
                        categories.push({ ...category, isRecipe: true });
                    }
                });
            }
            if (
                this.props.onlineShopCategories["articles_" + instance.url] &&
                this.props.onlineShopCategories["articles_" + instance.url][0]
            ) {
                this.props.onlineShopCategories["articles_" + instance.url][0].forEach((category) => {
                    if (
                        category.showInOnlineShopHeader &&
                        (this.props.onlineShopCategories.onlineShopItemType === -1 ||
                            category.onlineShopItemType === this.props.onlineShopCategories.onlineShopItemType)
                    ) {
                        categories.push({ ...category, isRecipe: false });
                    }
                });
            }
        });
        this.setState({
            categories: categories,
        });
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    toggleCustomerData() {
        const { account, toggleCustomerData } = this.props;
        const customer = account?.customer;

        if (customer) {
            const hasActivePhoneNumber = customer.phonenumbers.some((phone) => !phone.deleted);

            if (!customer.title || !customer.firstName || !customer.familyName || !hasActivePhoneNumber) {
                this.setState({ userInformationPopupVisible: true });
                return;
            }
        }

        toggleCustomerData();
    }

    toggleUserInformationPopup() {
        this.setState({ userInformationPopupVisible: !this.state.userInformationPopupVisible });
    }

    async handleDoOrder(customerData, paymentTypesId) {
        const customerDataCopy = JSON.parse(JSON.stringify(customerData));
        const updatedCustomerData = updateCountryNames(customerDataCopy, this.props.settings.store.countries);
        const { toggleCustomerData } = this.props;
        toggleCustomerData();
        this.props.setLoading("Bestellung wird aufgenommen", undefined);
        const order = this.createOrder(updatedCustomerData, paymentTypesId);

        const accessToken = getAccessToken();

        await store
            .dispatch(postOrder(order, accessToken))
            .then(async (json) => {
                if (json.orderUid !== undefined) {
                    this.props.setLoading("Bezahlvorgang startet gleich", undefined);
                    var url = "";
                    await store
                        .dispatch(getPaymentLink(json.orderUid, paymentTypesId))
                        .then((result) => {
                            url = result.paymentLink;
                        })
                        .catch(() => {
                            this.props.setLoading(false);
                            this.errorMessage();
                        });
                    if (url !== "") {
                        this.props.setLoading("Sie werden weitergeleitet zum Bezahlvorgang in ", undefined, url);
                        this.redirectCountdown(url);
                    } else {
                        this.props.setLoading(false);
                        this.errorMessage();
                    }
                } else {
                    this.props.setLoading(false);
                    this.errorMessage();
                }
            })
            .catch(() => {
                this.props.setLoading(false);
                this.errorMessage();
            });
    }

    createOrder(customerData, paymentTypesId) {
        const date = new Date();
        const timeZoneOffset = date.getTimezoneOffset() * 60000;
        const localISOTime = new Date(date - timeZoneOffset).toISOString().slice(0, -1);
        const { cart, zipCode, customer, account } = this.props;

        let countryName;
        if (customerData.deliveryAddress) {
            const parsedDeliveryAddress = JSON.parse(customerData.deliveryAddress);
            if (parsedDeliveryAddress && parsedDeliveryAddress.country) {
                countryName = parsedDeliveryAddress.country;
            }
        }

        if (!countryName) {
            countryName = customerData.country;
        }
        const country = getCountryByName(countryName, this.props.settings.store.countries);
        countryName = country.isoCode;
        const dynamicMinOrderChargeValue = this.props.settings.store.dynamicMinOrderCharge || false;

        let cartValue = 0;
        cart.orderItems.forEach((item) => (cartValue += item.Price.price1));
        const deliveryFreeAtValue = country?.deliveryFreeAt ?? 0;
        const deliverLumpSumValue = cartValue < deliveryFreeAtValue ? country.deliveryFee : 0;

        let billingAddress = customerData.billingAddress
            ? JSON.parse(customerData.billingAddress)
            : {
                  title: customerData.billingTitle,
                  firstName: customerData.billingFirstName,
                  lastName: customerData.billingLastName,
                  street: customerData.billingStreet,
                  housenumber: customerData.billingHousenumber,
                  zip: customerData.billingZipCode,
                  city: customerData.billingCity,
                  country: customerData.billingCountry,
              };
        let deliveryAddress = customerData.deliveryAddress
            ? JSON.parse(customerData.deliveryAddress)
            : {
                  title: customerData.title,
                  firstName: customerData.firstName,
                  lastName: customerData.lastName,
                  street: customerData.txtStreet,
                  housenumber: customerData.housenumber,
                  zip: customerData.txtZipCode,
                  city: customerData.txtCity,
                  country: customerData.country,
              };

        return {
            start: new Date().toISOString(),
            orderTypeId: 10,
            priceGroupId: 1,
            orderState: 1,
            orderIsReversed: false,
            uid: uuidv4(),
            orderTypeName: "Lieferung",
            paymentTypesId: paymentTypesId,
            comment: customerData.comment,
            deliverLumpSum: deliverLumpSumValue,

            // Create TransactionBuyers object
            TransactionBuyers: {
                Customer: {
                    customersId: account?.customer?.customersId || null,
                },
                customerId: account?.customer?.customersId || null,
                name: billingAddress
                    ? `${billingAddress.firstName} ${billingAddress.lastName}`
                    : `${customerData.firstName} ${customerData.lastName}`,
                billingStreet: billingAddress ? billingAddress.street : customerData.txtStreet,
                billingHousenumber: billingAddress ? billingAddress.housenumber : customerData.housenumber,
                billingPostal_code: billingAddress ? billingAddress.zip : customerData.txtZipCode,
                billingCity: billingAddress ? billingAddress.city : customerData.txtCity,
                deliveryStreet: deliveryAddress ? deliveryAddress.street : customerData.txtStreet,
                deliveryHousenumber: deliveryAddress ? deliveryAddress.housenumber : customerData.housenumber,
                deliveryPostal_code: deliveryAddress ? deliveryAddress.zip : customerData.txtZipCode,
                deliveryCity: deliveryAddress ? deliveryAddress.city : customerData.txtCity,
                deliveryFirstName: deliveryAddress ? deliveryAddress.firstName : customerData.firstName,
                deliveryLastName: deliveryAddress ? deliveryAddress.lastName : customerData.lastName,
                deliveryAddressId: deliveryAddress ? deliveryAddress.addressId : 0,
                deliveryCountry_code: countryName,
                buyerType: 0,
            },

            // Create Transactions array
            Transactions: [
                {
                    TransactionDatas: {
                        TransactionLines: cart.orderItems.map((item) => ({
                            TransactionLineItems: {
                                transactionLineItemId: uuidv4(),
                                quantity: item.entry.amount,
                                articleSizeId: item.ArticleSize.articleSizesId,
                                price_per_unit: item.Price.price1,
                                total_price: item.Price.price1 * item.entry.amount,
                                quantity_factor: 1,
                                Articles: {
                                    articlesId: item.entry.id,
                                    name: item.entry.name,
                                },
                            },
                        })),
                    },
                },
            ],
        };
    }

    errorMessage() {
        showToast("Es ist zu einem Fehler beim Bezahlvorgang gekommen", "warning", "error");
    }

    redirectCountdown(url) {
        var counter = setInterval(() => {
            if (this.props.redirectCounter <= 0) {
                if (Platform.OS === "web") {
                    window.location.href = url;
                    clearInterval(counter);
                } else {
                    Linking.canOpenURL(url)
                        .then((canOpen) => {
                            if (canOpen) {
                                Linking.openURL(url).then(() => {
                                    clearInterval(counter);
                                });
                            } else {
                                console.log("Cannot open URL: " + url);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }

            this.props.setRedirectCounter(this.props.redirectCounter - 1);
        }, 1000);
    }

    getIsoTimeString(date) {
        const returnDate = new Date(parseInt(date) * 1000);
        returnDate.setHours(returnDate.getHours() + 5);
        const timeZoneOffset = returnDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(returnDate - timeZoneOffset).toISOString().slice(0, -1);
        return localISOTime;
    }

    render() {
        const { windowWidth, windowHeight } = this.state;
        const {
            children,
            toggleLoginPopup,
            logout,
            toggleCustomerData,
            toggleCart,
            toggleMobileMenu,
            cartOpen,
            mobileMenuOpen,
            customerDataOpen,
            authLoading,
        } = this.props;

        var filteredCategories = [];
        if (this.state.categories) {
            {
                this.state.categories.forEach((category) => {
                    var filteredOnlineShopItems = filterOnlineShopItems(
                        category.OnlineShopItems,
                        this.props.settings.searchText
                    );
                    if (
                        filteredOnlineShopItems.length > 0 &&
                        this.props.onlineShopCategories.onlineShopItemType > -1 &&
                        this.props.onlineShopCategories.onlineShopItemType === category.onlineShopItemType &&
                        filteredCategories.filter((cat) => cat.categoriesId === category.categoriesId).length === 0
                    ) {
                        filteredCategories.push(category);
                    }
                });
            }
        }

        this.headerHeight =
            windowWidth > desktopBreakpoint
                ? this.props.onlineShopCategories.onlineShopItemType > -1 && filteredCategories.length > 0
                    ? "152px"
                    : "96px"
                : "64px";
        this.newHeaderHeight(this.headerHeight);
        return (
            <View nativeID="mainLayout" style={MainLayoutStyles.container}>
                <Header
                    toggleLoginPopup={toggleLoginPopup}
                    logout={logout}
                    onMenuClick={toggleMobileMenu}
                    onCartClick={toggleCart}
                    biosInstances={this.state.biosInstances}
                    authLoading={authLoading}
                />
                {windowWidth > desktopBreakpoint && <SubHeader biosInstances={this.state.biosInstances} />}
                {windowWidth > desktopBreakpoint &&
                    (this.props.onlineShopCategories.onlineShopItemType === 1 ||
                        this.props.onlineShopCategories.onlineShopItemType === 0) &&
                    this.props.onlineShopCategories.onlineShopItemType > -1 &&
                    filteredCategories.length > 0 && <CategoriesHeader filteredCategories={filteredCategories} />}
                <View
                    style={{
                        height: this.headerHeight,
                    }}
                />
                <MobileMenu
                    open={mobileMenuOpen}
                    onBackdropPress={toggleMobileMenu}
                    toggleLoginPopup={toggleLoginPopup}
                    logout={logout}
                    biosInstances={this.state.biosInstances}
                    filteredCategories={filteredCategories}
                    showCookieButton={this.props.showCookieButton}
                />
                <Cart open={cartOpen} onBackdropPress={toggleCart} toggleCustomerData={this.toggleCustomerData} />
                <CustomerData
                    open={customerDataOpen}
                    handleSubmit={this.handleDoOrder}
                    hideCustomerData={toggleCustomerData}
                    isRegistration={false}
                    submitButtonName={"Bestellen"}
                    updateCustomer={this.props.updateCustomer}
                />
                {children}
                {windowWidth < desktopBreakpoint && <View style={{ height: "64px" }} />}
                {windowWidth < desktopBreakpoint ? (
                    <Footer
                        toggleLoginPopup={toggleLoginPopup}
                        logout={logout}
                        onMenuClick={toggleMobileMenu}
                        onCartClick={toggleCart}
                        authLoading={authLoading}
                    />
                ) : (
                    <FooterDesktop />
                )}
                {this.state.userInformationPopupVisible && (
                    <UpdateUserInformationPopup
                        visible={this.state.userInformationPopupVisible}
                        windowHeight={windowHeight}
                        togglePopup={this.toggleUserInformationPopup}
                        toggleCart={toggleCart}
                    />
                )}
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { cart, settings, account, onlineShopCategories } = state;
    return { cart, settings, account, onlineShopCategories };
}
export default connect(mapStateToProps)(MainLayout);
