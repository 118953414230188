import React from "react";
import { View } from "react-native";
import NumberFormat from "react-number-format";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { Text } from "react-native-paper";

export default function ArticleList(props) {
    return (
        <View style={orderTrackingStyles.articleList}>
            <View>
                <Text variant="headlineSmall" style={orderTrackingStyles.headline}>
                    Artikel
                </Text>
            </View>
            <View>
                {props.Order.Transactions[0].TransactionDatas.TransactionLines.map((transactionLine, index) => {
                    const transactionLineItem = transactionLine.TransactionLineItems;
                    var extrasPrice = transactionLineItem.other_TransactionLineItems.reduce(
                        (sum, extra) => sum + parseFloat(extra.price_per_unit),
                        0
                    );
                    return (
                        <View
                            key={index}
                            style={orderTrackingStyles.cartRow}
                        >
                            <View style={orderTrackingStyles.articleTitle}>
                                {transactionLineItem.quantity > 0 && (
                                    <Text style={orderTrackingStyles.text}>{" " + transactionLineItem.quantity}x</Text>
                                )}
                                <Text style={orderTrackingStyles.text}>{" " + transactionLineItem.Articles.name}</Text>
                                {transactionLineItem.comment && (
                                    <View style={orderTrackingStyles.comment}>
                                        <Text style={orderTrackingStyles.text}>{transactionLineItem.comment}</Text>
                                    </View>
                                )}
                                {transactionLineItem.quantity > 1 && (
                                    <View style={orderTrackingStyles.priceSingle}>
                                        <NumberFormat
                                            value={Number(transactionLineItem.price_per_unit) + Number(extrasPrice)}
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator="."
                                            suffix=" €"
                                            displayType="text"
                                            renderText={(value) => (
                                                <Text style={orderTrackingStyles.text}>{` (${value})`}</Text>
                                            )}
                                        />
                                    </View>
                                )}
                            </View>
                            <View>
                                <NumberFormat
                                    value={
                                        transactionLineItem.quantity *
                                        (Number(transactionLineItem.price_per_unit) + Number(extrasPrice))
                                    }
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator="."
                                    suffix=" €"
                                    displayType="text"
                                    renderText={(value) => <Text style={orderTrackingStyles.text}>{value}</Text>}
                                />
                            </View>
                        </View>
                    );
                })}
                {props.Order.Transactions[0].deliverLumpSum && (
                    <View style={orderTrackingStyles.sumToPayRow}>
                        <Text style={orderTrackingStyles.text}>Liefergebühr</Text>
                        <NumberFormat
                            value={Number(props.Order.Transactions[0].deliverLumpSum)}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator="."
                            suffix=" €"
                            displayType="text"
                            renderText={(value) => <Text style={orderTrackingStyles.text}>{value}</Text>}
                        />
                    </View>
                )}
                {props.Order.Transactions[0].minOrderCharge && (
                    <View style={orderTrackingStyles.sumToPayRow}>
                        <Text style={orderTrackingStyles.text}>Mindermengenzuschlag</Text>
                        <NumberFormat
                            value={Number(props.Order.Transactions[0].minOrderCharge)}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator="."
                            suffix=" €"
                            displayType="text"
                            renderText={(value) => <Text style={orderTrackingStyles.text}>{value}</Text>}
                        />
                    </View>
                )}
                {props.Order.Transactions[0].paymentFee && (
                    <View style={orderTrackingStyles.sumToPayRow}>
                        <Text style={orderTrackingStyles.text}>Zahlungsgebühr</Text>
                        <NumberFormat
                            value={Number(props.Order.Transactions[0].paymentFee)}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator="."
                            suffix=" €"
                            displayType="text"
                            renderText={(value) => <Text style={orderTrackingStyles.text}>{value}</Text>}
                        />
                    </View>
                )}
                <View style={orderTrackingStyles.sumToPayRow}>
                    <Text style={orderTrackingStyles.text}>Gesamtsumme</Text>
                    <NumberFormat
                        value={Number(props.Order.Transactions[0].TransactionDatas.full_amount_incl_vat)}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        thousandSeparator="."
                        suffix=" €"
                        displayType="text"
                        renderText={(value) => <Text style={orderTrackingStyles.text}>{value}</Text>}
                    />
                </View>
            </View>
        </View>
    );
}
